import { Box, Divider, Link, Typography } from "@mui/material";
import unLogoMobile from "../../assets/logos/un-logo.png";
import unLogo from "../../assets/logos/un-logo-text.png";
import undidLogo from "../../assets/logos/undid-logo-text.svg";
import undidLogoMobile from "../../assets/logos/undid-logo-notext-blue.png";
import useResponsive from "../../hooks/useResponsive";
import cn from "classnames";

const PrivacyPolicy = () => {
  const { isMobile } = useResponsive();

  //#region Wallet Privacy Notice Texts
  const wMainTitle = "UN Digital Wallet Privacy Notice";
  const wMainParagraph = [
    "The UN Digital Wallet Application, \
    owned and provided by the United Nations International Computing Centre (UNICC) \
    on behalf of UN Organizations participating to UN Digital ID project, is designed \
    to provide convenient device-based storage for its users' UN-issued identifying documentation, \
    including but not limited to pension claims. \
    This privacy notice describes what personal data is processed through the Application. \
    Please note that the terms of this privacy notice may change without prior notification. \
    You are encouraged to review this policy regularly. You can contact UNICC at ",
    <Link key="privacyEmail" href="mailto:undigitalid@unicc.org">
      undigitalid@unicc.org
    </Link>,
    ".\n\nThis privacy notice only refers to the identifying information that is retrieved from a UN Agency \
    and stored on your device. It does not refer to any other personal data \
    that may be processed by any UN Agency for its own purposes.",
  ];
  const wUserDataTitle = "User data";
  const wUserDataParagraph =
    "The Application only transmits or stores identifying documentation \
  that has been voluntarily provided by its users or is authorized by its users \
  for retrieval from the UN Agency which holds the relevant identifying information. \
  \n\nIf you provide user data in order to obtain access to the application, \
  we will use such data to provide access to such services and to monitor use of such services \
  only for security purposes. UNICC does not have access to nor stores \
  any identifying information stored in the Application. Therefore, if you lose your log-in credentials, \
  you will need to create a new wallet and retrieve identifying information from the issuing UN agency. \
  \n\nThe application allows use of biometrics, to securely provide you access with your wallet. \
  Biometric information is only stored in the device, \
  is not transmitted online and therefore UNICC has no access to such information. \
  \n\nUNICC processes non-personal, aggregated data relating to the use of the Application \
  to ensure its performance, improve user experience and ensure the security. \
  \n\nThe Application may ask for your permission to access your mobile device's camera, \
  which is only used in the process of adding or verifying identifying documentation. \
  \n\nThe Application requires your local device storage to offline securely store your credentials.";
  const wDataSecurityTitle = "Data security";
  const wDataSecurityParagraph =
    "UNICC implements industry standard security measures \
  to protect User Data from unauthorized access, modification or destruction.";
  const wYourRightsTitle = "Your rights";
  const wYourRightsParagraph = [
    "We offer you choices regarding the storage, deletion, use \
    and sharing of your identifying documentation and we respect the choices you make. \
    If you decide not to retrieve, to delete or not to share your identifying information \
    with the relevant UN agencies you may not be able to avail of UN Digital ID services. \
    \n\nNothing contained in or relating to this Privacy Notice, or done pursuant to it, \
    shall be construed as a waiver of any of the privileges and immunities enjoyed by UNICC \
    under national or international law, and/or as submitting UNICC to any national court jurisdiction. \
    Without limiting the generality of the previous sentence, any disclosure of Personal Data \
    in response to a request for disclosure in accordance with this Policy will not constitute a waiver, \
    express or implied, of any of the privileges and immunities of UNICC. \
    \n\nUNICC complies with the UN Personal Data Protection and Privacy Principles \
    and with the WHO Personal Data Protection Policy available at ",
    <Link
      key="whoPolicy"
      href="https://www.who.int/about/policies/publishing/data-policy"
      target="_blank"
    >
      WHO Data Policy
    </Link>,
    ".",
  ];

  const walletPolicyList = [
    { title: wMainTitle, paragraph: wMainParagraph, isMain: true },
    { title: wUserDataTitle, paragraph: wUserDataParagraph, isMain: false },
    {
      title: wDataSecurityTitle,
      paragraph: wDataSecurityParagraph,
      isMain: false,
    },
    { title: wYourRightsTitle, paragraph: wYourRightsParagraph, isMain: false },
  ];
  //#endregion

  //#region Backup Privacy Notice Texts
  const bMainTitle = "Backup Service Privacy Notice";
  const bMainParagraph = [
    "The UN Digital Wallet Application, \
    owned and provided by the United Nations International Computing Centre (UNICC) \
    on behalf of UN Organizations participating to UN Digital ID project, is designed \
    to provide convenient device-based storage for its users' UN-issued identifying documentation, \
    including but not limited to pension claims. \
    This privacy -notice describes what personal data is processed when you back up your wallet. \
    Please note that the terms of this privacy notice may change without prior notification. \
    You are encouraged to read the digital wallet Q&A if you need more information. \
    You can contact UNICC at ",
    <Link key="privacyEmail" href="mailto:undigitalid@unicc.org">
      undigitalid@unicc.org
    </Link>,
    ".\n\nThis privacy notice only refers to the processing of your personal data \
    when you back up your digital wallet.",
  ];
  const bOptionalServiceTitle = "Optional service";
  const bOptionalServiceParagraph =
    "The backup service is an optional feature of the UN Digital ID. If you chose \
    not to backup your credentials, you will still be able to use all UN Digital ID services. \
    However, if you lose or change your device where the digital wallet is installed, \
    you will need to retrieve your credentials from the issuing organizations.";
  const bCredentialsTitle = "Credentials";
  const bCredentialsParagraph =
    "When you back up your digital wallet, UNICC stores in a secure environment \
    all credentials that are stored in your digital wallet.";
  const bBiometricsTitle = "Biometrics";
  const bBiometricsParagraph =
    "When you back up your digital wallet, UNICC stores your biometric templates, \
    which are subsequently used to authenticate you when you retrieve your backed-up wallet. \
    Biometrics data are not and will never be used for any additional purpose.";
  const bDataSecurityTitle = "Data security";
  const bDataSecurityParagraph =
    "Your wallet is only identified by an identifier. UNICC is not able to link the backed-up wallet to you. \
    \n\nIn order to give you assurance that UNICC is not able to access your personal data, \
    both your credentials and your biometric templates are encrypted on your device \
    using the encryption words before being sent to UNICC for the backup. \
    This means that UNICC will never be able to decrypt your personal data. \
    \n\nUNICC environment is monitored 24/7 by the security operations center \
    and a data breach response process is in place.";
  const bYourRightsTitle = "Your rights";
  const bYourRightsParagraph = [
    "We offer the choice to access the information in your backed up wallet with the use of your PIN. \
    \n\nNothing contained in or relating to this Privacy Notice, or done pursuant to it, \
    shall be construed as a waiver of any of the privileges and immunities enjoyed by UNICC \
    under national or international law, and/or as submitting UNICC to any national court jurisdiction. \
    Without limiting the generality of the previous sentence, any disclosure of Personal Data \
    in response to a request for disclosure in accordance with this Policy will not constitute a waiver, \
    express or implied, of any of the privileges and immunities of UNICC. \
    \n\nUNICC complies with the UN Personal Data Protection and Privacy Principles \
    and with the WHO Personal Data Protection Policy available at ",
    <Link
      key="whoPolicy"
      href="https://www.who.int/about/policies/publishing/data-policy"
      target="_blank"
    >
      WHO Data Policy
    </Link>,
    ".",
  ];

  const backupPolicyList = [
    { title: bMainTitle, paragraph: bMainParagraph, isMain: true },
    {
      title: bOptionalServiceTitle,
      paragraph: bOptionalServiceParagraph,
      isMain: false,
    },
    {
      title: bCredentialsTitle,
      paragraph: bCredentialsParagraph,
      isMain: false,
    },
    {
      title: bBiometricsTitle,
      paragraph: bBiometricsParagraph,
      isMain: false,
    },
    {
      title: bDataSecurityTitle,
      paragraph: bDataSecurityParagraph,
      isMain: false,
    },
    { title: bYourRightsTitle, paragraph: bYourRightsParagraph, isMain: false },
  ];
  //#endregion

  //#region Renderers
  const walletPrivacyNotice = walletPolicyList.map((pol, index) => {
    const { title, paragraph, isMain } = pol;
    return (
      <Box key={index} className={isMain ? "space-y-8" : "space-y-1"}>
        <Typography variant={isMain ? "h1" : "h2"}>{title}</Typography>
        <Typography whiteSpace="pre-line">{paragraph}</Typography>
      </Box>
    );
  });

  const backupPrivacyNotice = backupPolicyList.map((pol, index) => {
    const { title, paragraph, isMain } = pol;
    return (
      <Box key={index} className={isMain ? "space-y-8" : "space-y-1"}>
        <Typography variant={isMain ? "h1" : "h2"}>{title}</Typography>
        <Typography whiteSpace="pre-line">{paragraph}</Typography>
      </Box>
    );
  });
  //#endregion

  return (
    <Box
      data-testid="privacyPolicy"
      className={isMobile ? "p-8 space-y-10" : "p-14 space-y-20"}
    >
      <Box className={cn("flex space-x-12", isMobile && "justify-between")}>
        <img
          src={isMobile ? unLogoMobile : unLogo}
          className="h-16"
          alt="united nations logo"
        />
        {!isMobile && (
          <Divider
            orientation="vertical"
            flexItem
            aria-hidden="true"
            className="border-black opacity-50"
          />
        )}
        <img
          src={isMobile ? undidLogoMobile : undidLogo}
          className="h-16"
          alt="digital id logo"
        />
      </Box>
      <Box className={cn("space-y-5", isMobile ? "px-2" : "px-24")}>
        {walletPrivacyNotice}
      </Box>
      <Box className={cn("space-y-5", isMobile ? "px-2" : "px-24")}>
        {backupPrivacyNotice}
      </Box>
    </Box>
  );
};

export { PrivacyPolicy };
