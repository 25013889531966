import { debounce } from "lodash";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { TableSort } from "../components/Table/TableTypes";
import NetworkServiceFactory from "../core/services/network/NetworkService";

type SearchQueryProps = {
  onSearch: (a: any) => void;
  debounce?: number;
};

export const useSearchHelper = <DataFields extends string>({
  debounce: delay,
  onSearch,
}: SearchQueryProps) => {
  const [search, setSearch] = useState<string>();
  const [switchValue, setSwitchValue] = useState<boolean>(false);
  const [ordering, setOrdering] = useState<TableSort<DataFields>>();
  const [page, setPage] = useState<number>(1);
  const [refresh, setRefresh] = useState<boolean>(false);

  const doSearch = useCallback(debounce(onSearch, delay ?? 100), []);

  useEffect(() => {
    doSearch({ search, page, ordering, switchValue });
  }, [search, page, ordering, switchValue]);

  useEffect(() => {
    if (refresh) {
      // Reset cache when refresh is needed
      NetworkServiceFactory.shared.clearCache();
      doSearch({ search, page, ordering, switchValue });
      setRefresh(false);
    }
  }, [refresh]);

  const setWithPageReset = <T>(
    setter: Dispatch<SetStateAction<T>>,
    value: T
  ) => {
    setPage(1);
    setter(value);
  };

  return {
    setPage,
    setSearch: (searchTerm: string | undefined) =>
      setWithPageReset<string | undefined>(setSearch, searchTerm),
    setOrdering,
    setSwitchValue: (value: boolean) => setWithPageReset(setSwitchValue, value),
    tableProps: {
      ordering,
      page,
      search,
      switchValue,
      onPageChange: setPage,
      onRefresh: () => setRefresh(true),
      onSort: setOrdering,
    },
  };
};
