import { Box, Divider, Link, List, ListItem, Typography } from "@mui/material";
import unLogoMobile from "../../assets/logos/un-logo.png";
import unLogo from "../../assets/logos/un-logo-text.png";
import undidLogo from "../../assets/logos/undid-logo-text.svg";
import undidLogoMobile from "../../assets/logos/undid-logo-notext-blue.png";
import useResponsive from "../../hooks/useResponsive";
import cn from "classnames";

const TermsOfService = () => {
  const { isMobile } = useResponsive();

  //#region Texts
  const mainTitle = "UN Digital Wallet Terms of Use";
  const dateTitle = "Effective Date:";
  const dateParagraph = "3 June 2024";
  const mainParagraph =
    'Welcome to the UN Digital ID mobile app (hereinafter the "App") \
  maintained by the United Nations International Computing Centre ("UNICC") \
  on behalf of the participating organizations of UN Digital ID.\
  \n\nBy accessing and using this App, you agree to comply with and be bound \
  by the following terms and conditions of use ("Terms of Use"). Please read \
  these Terms of Use carefully before using the App.';
  const firstTitle = "1. Acceptance of Terms";
  const firstParagraph =
    "By accessing the App, you accept and agree to be bound \
  by these Terms of Use. If you do not agree to these Terms of Use, please do not use the App.";
  const secondTitle = "2. Authentication and Access";
  const secondParagraph = [
    "Access to this App requires authentication through login credentials. \
    You are responsible for maintaining the confidentiality of your authentication details \
    and for any activities that occur under your account.\
    \n\nYou agree to notify UNICC immediately of any unauthorized use of your account \
    or any other breach of security at ",
    <Link key="securityEmail" href="mailto:csoc@unicc.org">
      csoc@unicc.org
    </Link>,
    ".",
  ];
  const thirdTitle = "3. Use of App";
  const thirdParagraph =
    "This App is provided to you free of charge for your personal, non-commercial use only.\
  \n\nUN Digital ID, including this App, is agnostic with respect to the purposes \
  for which data is exchanged by participating organizations and users. The purposes are defined \
  by participating organizations collectively as data controllers, who retain ultimate responsibility \
  for the personal data and its processing.\
  \n\nThe submission of and content or data to the App does not imply UNICC's approval or endorsement \
  of that content or data, or that the content or data is appropriate for any purpose \
  or meets any established standard or requirement.\
  \n\nAny designations employed in, or presentation by the user in its use of, the App, do not imply \
  the expression of any opinion whatsoever on the part of UNICC or its Partners or Users \
  concerning the legal status of any country, territory, city or area or of its authorities, \
  or concerning the delimitation of its frontiers and boundaries.\
  \n\nThe App may contain links and references to third-party apps. The linked sites \
  are not under the control of UNICC, and UNICC is not responsible for the content of any linked site \
  or any link contained in a linked site. These links are provided only as a convenience, \
  and the inclusion of a link or reference does not imply the endorsement of the linked site by UNICC.";
  const fourthTitle = "4. Specific Information concerning the Digital Wallet";
  const fourthParagraph = [
    "The App functions as a digital wallet which allows you to\n",
    <List key="fourthList">
      <ListItem key="firstItem">
        {
          "- retrieve personal data in the form of verifiable credentials \
      from the issuing organization (your employing organization), and"
        }
      </ListItem>
      <ListItem key="secondItem">
        {
          "- present them to the verifier (another organization of the UN system) \
          in the form of a verifiable presentation."
        }
      </ListItem>
    </List>,
    "\nThe App does not collect your personal data, does not access any personal data \
    stored in your device, and has undergone cybersecurity checks from Google and Apple \
    in order to be published in the relevant app stores.\
    \n\nNo data retrieval or sharing through the App can proceed without you actively initiating it \
    and providing your consent.\
    \n\nUNICC does not take any responsibility for the correctness, accuracy or completeness \
    of any information or data processed through or with the help of this App, \
    which is retrieved from the issuing organizations (your employing organization).",
  ];
  const fifthTitle = "5. Use of Optional Digital Wallet Back-up Function";
  const fifthParagraph =
    "The back-up system of the digital wallet allows users \
  to back up their credentials in the UN Digital ID system back end, which is maintained by UNICC. \
  Using this function enables you to restore your wallet in the event of loss \
  or change of the mobile device or deletion of the App, etc.\
  \n\nThe backup function requires you to provide biometric data for authentication purposes. \
  Collecting this data is necessary to uniquely identify you as the individual \
  who is recovering the digital wallet and to ensure that it is the same person \
  who originally performed the back-up operation.\
  \n\nYour use of the digital wallet back-up feature is optional. If you wish to use \
  the digital wallet back-up feature, your consent is required and covers the processing of \
  your personal data, including storage of your biometric data by UNICC.\
  \n\nIf you chose not to use the backup service and your mobile device is lost, \
  exchanged for a new device, or the app is uninstalled on your device, you will be required \
  to follow the process to obtain all credentials originally stored in your digital wallet \
  by requesting new credentials from the issuer (your employing organization).\
  \n\nHowever, not using the backup feature does not affect in any way your use of UN Digital ID \
  or the digital wallet application.";
  const sixthTitle = "6. Prohibited Uses";
  const sixthParagraph =
    "You agree not to use the App in any way that is unlawful, illegal or unauthorised; \
    advocates, promotes or assists any unlawful use; or is likely to disrupt the use of the App.";
  const seventhTitle = "7. Limitations of Liability";
  const seventhParagraph =
    "UNICC maintains and provides this App on an 'as is' and 'as available' \
  basis without any promises or representations, express or implied. In particular, we do not warrant \
  or make any representation regarding the validity, accuracy, reliability or availability of the App \
  or its content.\
  \n\nTo the fullest extent possible, we hereby exclude all express or implied promises, including \
  that the App is fit for purpose, of satisfactory quality, non-infringing, free of defects, able to \
  or will operate without interruption, that the use of the App by you is in compliance with laws, \
  or that any information that you transmit in connection with this App will be successfully, \
  accurately or securely transmitted.\
  \n\nIn no event shall the UNICC or any developer or contributor of the App (collectively \
  \"Non-Liable Parties\") be liable to you with respect to use of the App and/or be liable to you \
  for any direct, indirect, special or consequential damages including, without limitation, \
  damages for loss of goodwill, lost profits, or loss, theft or corruption of your information, \
  the inability to use the App, device failure or malfunction. The Non-Liable Parties shall not be \
  liable even if they or any of them has been advised of the possibility of such damages, \
  including without limitation damages caused by error, omission, interruption, defect, \
  failure of performance, unauthorised use, delay in operation or transmission, line failure, \
  computer virus, worm, Trojan horse or other harm.";
  const eighthTitle = "8. Privacy";
  const eighthParagraph =
    "Please review the UN Digital ID Privacy Notice associated with this App, which informs \
    users of the way UNICC processes personal data.";
  const ninthTitle = "9. Trademarks and logos";
  const ninthParagraph =
    "The UNICC name and logo, and other trademarks, service marks, graphics and logos \
    used in connection with the App are trademarks of the UNICC (collectively “UNICC Trademarks”). \
    Other trademarks, service marks, graphics and logos used in connection with the App are \
    the trademarks of their respective owners (collectively “Third-Party Trademarks”). \
    The App is protected by applicable intellectual property and proprietary rights.";
  const tenthTitle = "10. Licenses";
  const tenthParagraph =
    "We grant you a non-exclusive, non-transferable, revocable licence to use the App for \
    your personal, non-commercial use as permitted by the applicable device platform terms \
    and in accordance with these Terms of Use (“User Licence”). All other rights \
    in the App are reserved by UNICC. Your breach of these Terms of Use immediately terminates \
    your User Licence.";
  const eleventhTitle = "11. General";
  const eleventhParagraph =
    "Any matter relating to the interpretation or application of these \
  Terms of Use which is not covered by their terms shall be resolved by reference to Swiss law. \
  Any dispute relating to the interpretation or application of these Terms of Use shall, \
  unless amicably settled, be subject to conciliation. In the event of failure of the latter, \
  the dispute shall be settled by arbitration. The arbitration shall be conducted in accordance \
  with the modalities to be agreed upon by the parties or, in the absence of agreement, \
  in accordance with the UNCITRAL arbitration rules. The parties shall accept the arbitral award \
  as final.\
  \n\nIf any provision (or part of a provision) of these Terms of Use is found to be invalid \
  or unenforceable, such term, condition or provision will to that extent be severed from the remaining \
  terms, conditions and provisions which will continue to be valid to the fullest extent possible.\
  \n\nThe use of third-party components or other third-party software does not imply that \
  these products are endorsed or recommended by UNICC in preference to others of a similar nature.";
  const twelfthTitle = "12. Preservation of Privileges and Immunities";
  const twelfthParagraph =
    "Nothing contained herein or in any license or terms of use related to the subject matter \
    herein shall be construed as a waiver of any of the privileges and immunities enjoyed by UNICC \
    or its Partners or Users under national or international law, and/or as submitting UNICC \
    or its Partners or Users to any national court jurisdiction.";
  const thirteenthTitle = "13. Changes to the Terms of Use";
  const thirteenthParagraph =
    "UNICC may revise these Terms of Use from time to time without prior notice and accepts \
    no liability for any errors or omissions in this regard. Revised Terms of Use will apply \
    to the use of this App from the date of publication of the revised Terms of Use on this App.";
  const fourteenthTitle = "14. Contact Information";
  const fourteenthParagraph = [
    "If you have any questions about these Terms of Use, please contact us at ",
    <Link key="supportEmail" href="mailto:undigitalid@unicc.org">
      undigitalid@unicc.org
    </Link>,
    ".",
  ];

  const termsList = [
    { title: mainTitle, paragraph: null, isMain: true },
    { title: dateTitle, paragraph: dateParagraph, inline: true },
    { title: null, paragraph: mainParagraph },
    { title: firstTitle, paragraph: firstParagraph },
    { title: secondTitle, paragraph: secondParagraph },
    { title: thirdTitle, paragraph: thirdParagraph },
    { title: fourthTitle, paragraph: fourthParagraph },
    { title: fifthTitle, paragraph: fifthParagraph },
    { title: sixthTitle, paragraph: sixthParagraph },
    { title: seventhTitle, paragraph: seventhParagraph },
    { title: eighthTitle, paragraph: eighthParagraph },
    { title: ninthTitle, paragraph: ninthParagraph },
    { title: tenthTitle, paragraph: tenthParagraph },
    { title: eleventhTitle, paragraph: eleventhParagraph },
    { title: twelfthTitle, paragraph: twelfthParagraph },
    { title: thirteenthTitle, paragraph: thirteenthParagraph },
    { title: fourteenthTitle, paragraph: fourteenthParagraph },
  ];
  //#endregion

  //#region Renderers
  const sections = termsList.map((pol, index) => {
    const { title, paragraph, isMain, inline } = pol;
    return (
      <Box
        key={index}
        className={cn(
          isMain ? "space-y-8" : inline ? "space-y-0" : "space-y-4",
          inline ? "flex space-x-2" : ""
        )}
      >
        {title && (
          <Typography variant={isMain ? "h1" : "h2"}>{title}</Typography>
        )}
        {paragraph && (
          <Typography whiteSpace="pre-line">{paragraph}</Typography>
        )}
      </Box>
    );
  });
  //#endregion

  return (
    <Box
      data-testid="termsOfService"
      className={isMobile ? "p-8 space-y-10" : "p-14 space-y-20"}
    >
      <Box className={cn("flex space-x-12", isMobile && "justify-between")}>
        <img
          src={isMobile ? unLogoMobile : unLogo}
          className="h-16"
          alt="united nations logo"
        />
        {!isMobile && (
          <Divider
            orientation="vertical"
            flexItem
            aria-hidden="true"
            className="border-black opacity-50"
          />
        )}
        <img
          src={isMobile ? undidLogoMobile : undidLogo}
          className="h-16"
          alt="digital id logo"
        />
      </Box>
      <Box className={cn("space-y-5", isMobile ? "px-2" : "px-24")}>
        {sections}
      </Box>
    </Box>
  );
};

export { TermsOfService };
